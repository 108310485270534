.custom-restaurants-list {
  margin-top: 2%;
}

.custom-restaurants-list-note {
  color: rgba(15, 15, 15, 0.5);
}

.custom-restaurants-list-spinner {
  margin: auto;
  margin-left: 50%;
}

.custom-restaurants-list-show-more {
  color: var(--primary-clr);
  cursor: pointer;
  display: block;
  margin-top: 2%;
}
@media (hover: hover) {
  .custom-restaurants-list-show-more:hover {
    opacity: 0.8;
  }
}
