.custom-footer {
    position: relative;
    margin-top: auto;
    background-image: url('../../assets/gradient.svg');
    background-size: contain;
}

.custom-footer-sidebar {
    background-image: none;
    background-color: #FFCB01;
    margin-top: 0 !important;
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
}

.custom-contact-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 374px;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 6%;

    background-color: #FFCB01;
}

.custom-contact-info-sidebar {
    justify-content: start;
    height: unset;
    padding-left: 3%;
}

.custom-footer-image {
    position: absolute;
    bottom: 0;
    right: 0;
}

@media (max-width: 992px) {
    .custom-footer-image {
        width: 24%;
    }
}

@media (max-width: 927px) {
    .custom-footer {
        margin-top: 15%;
    }

    .custom-footer-image {
        width: 20%;
    }
}

@media (max-width: 812px) {
    .custom-footer {
        margin-top: 25%;
    }
}

@media (max-width: 768px) {
    .custom-footer {
        margin-top: 10%;
    }

    .custom-footer-image {
        width: 25%;
    }
}

@media (max-width: 587px) {
    .custom-contact-info {
        padding-right: 3%;
    }
}

@media (max-width: 426px) {
    .custom-contact-info {
        height: auto;
        padding-left: 3%;
    }

    .custom-footer-image {
        width: 30%;
    }
}

@media (max-width: 376px) {
    .custom-contact-info {
        padding-right: 0;
    }
}