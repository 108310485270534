:root {
  --primary-clr: #ffcb01;
  --primary-text-clr: #0f0f0f;
  --secondary-text-clr: rgba(15, 15, 15, 0.5);
  --main-transition: color, background-color 0.7s;
  --price-clr: #01a640;
}

.App a {
  text-decoration: none;
}

.App {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;

  font-family: "Poppins";
  color: #0f0f0f;
}

.highlight-element {
  border: 1px solid #fd0606;
  border-radius: 6px;
  padding: 3px 0;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffcb01;
}

body *::-webkit-scrollbar {
  width: 6px;
}
