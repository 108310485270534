.custom-list-group {
    --bs-list-group-bg: none;
    --bs-list-group-border-width: none;
    z-index: 2;
}

.custom-list-item {
    padding-left: 0;
}

.custom-list-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px; 
    color: #0F0F0F;
}

.custom-list-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;
    color: #0F0F0F;
}
@media (hover: hover) {
    .custom-list-text:hover {
        opacity: 0.8;
        color: #0F0F0F;
    }
}

@media (max-width: 587px) {
    .custom-list-group {
        flex: 0.9;
    }
}

@media (max-width: 576px) {
    .custom-list-group {
        flex: 0.4;
    }
    .custom-list-group:last-child {
        flex: 0.2;
    }
}

@media (max-width: 568px) {
    .custom-list-group {
        flex: 0 1 auto;
    }
}

@media (max-width: 426px) {
    .custom-list-group {
        margin-bottom: 15px;
        flex-basis: 90%;
        align-items: center;
    }
}