.custom-legal-info-container {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(15, 15, 15, 0.1);
  z-index: 5;
}

.custom-legal-info-container-sidebar {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 32%;
  padding: 0.5rem;
  padding-left: 5%;
  margin-bottom: 25%;
}

.custom-span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #0f0f0f;
}

.custom-anchor-tag {
  text-decoration: none;
  cursor: pointer;
}

.custom-policy-container {
  display: inline-flex;
  justify-content: space-around;
  flex: 0.25;
  gap: 0.5rem;
}

.custom-policy-container-sidebar {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 0.6;
}

.custom-policy-container-sidebar > a {
  font-size: 14px;
}

.custom-legal-info-sidebar-header {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.custom-legal-info-sidebar-link {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: rgba(15, 15, 15, 0.5);
}

@media (hover: hover) {
  .custom-anchor-tag:hover {
    color: #0f0f0f;
  }

  .custom-legal-info-sidebar-link:hover,
  .custom-legal-info-sidebar-link:active,
  .custom-legal-info-sidebar-link.active {
    color: #fd0606;
  }
}

@media (max-width: 1280px) {
  .custom-policy-container {
    flex: 0.4;
  }
}

@media (max-width: 1024px) {
  .custom-policy-container {
    flex: 0.48;
  }
}

@media (max-width: 587px) {
  .custom-policy-container {
    flex: 0.8;
    padding-right: 8%;
  }
}

@media (max-width: 568px) {
  .custom-legal-info-container {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .custom-policy-container {
    padding-bottom: 10px;
  }
}

@media (max-width: 451px) {
  .custom-legal-info-container-sidebar {
    margin-bottom: 21%;
  }
}

@media (max-width: 376px) {
  .custom-policy-container {
    flex: 0.88;
  }
}
